const routes = require('next-routes');

//
// Because of awesome Next.js, You don't need to add routes for all pages.
// Every file on Pages folder basically has route as they named.
// (index.js => /, about.js => /about, ...etc.)
//
// If you want to change url (for SEO or put different path), please add your route below.
// for more info, please look at https://github.com/Sly777/ran/blob/master/docs/Routing.md
//
//
// Please add your route between of comments
//
// ------------ ROUTES ---------------
// @RANStartRoutes
// @RANEndRoutes
// ------------ ROUTES ---------------
//
//

// module.exports = routes;
module.exports = routes()

    .add({
      name: "home",
      pattern: '/',
      page: "home"
    })
    .add({
      name: "concept",
      pattern: '/concept',
      page: "concept"
    })
    .add({
      name: "spending-information",
      pattern: '/depenser',
      page: "depenser"
    })
    .add({
      name: "terms",
      pattern: '/conditions-generales-utilisation',
      page: "conditions-generales-utilisation"
    })
    .add({
      name: "faq",
      pattern: '/faq',
      page: "faq"
    })
    .add({
      name: "error",
      pattern: '/error',
      page: "error/Error500"
    })

    /*GIFTS PAGES*/
    .add({
      name: "gifts",
      pattern: '/sinspirer',
      page: "sinspirer"
    })
    .add({
      name: "gift",
      pattern: '/sinspirer/:reference/:productName',
      page: "sinspirer/[reference]/[productName]"
    })
    .add({
      name: "gift-create-gift-card",
      pattern: '/sinspirer/:reference/:productName/carte-cadeau',
      page: "sinspirer/[reference]/[productName]/carte-cadeau"
    })
    .add({
      name: "gift-gift-card-confirm",
      pattern: '/sinspirer/:reference/:productName/carte-cadeau/:potReference/confirmation/:orderReference',
      page: "sinspirer/[reference]/[productName]/carte-cadeau/[potReference]/confirmation/[orderReference]"
    })


    /*POT PAGES*/
    .add({
      name: "pot",
      pattern: '/cagnotte/:reference',
      page: "cagnotte/[reference]"
    })
    .add({
      name: "create-pot",
      pattern: '/creer-une-cagnotte',
      page: "cagnotte/create"
    })
    .add({
      name: "participate",
      pattern: '/cagnotte/:reference/participer',
      page: "cagnotte/[reference]/participer"
    })
    .add({
      name: "payment-done",
      pattern: '/cagnotte/:reference/paiement-termine',
      page: "cagnotte/[reference]/paiement-termine"
    })
    .add({
      name: "greeting-card",
      pattern: "/cagnotte/:reference/carte-de-voeux",
      page: "cagnotte/[reference]/carte-de-voeux"
    })
    .add({
      name: "manage-pot",
      pattern: '/cagnotte/:reference/modification',
      page: 'cagnotte/[reference]/modification'
    })
    .add({
      name: "use-money",
      pattern: '/cagnotte/:reference/utiliser-la-cagnotte',
      page: 'cagnotte/[reference]/utiliser-la-cagnotte'
    })
    .add({
      name: "giveaway",
      pattern: '/cagnotte/:reference/offrir',
      page: 'cagnotte/[reference]/offrir'
    })
    .add({
      name: "cash-out",
      pattern: '/cagnotte/:reference/demander-un-virement',
      page: 'cagnotte/[reference]/demander-un-virement'
    })
    .add({
      name: "cash-out-confirm",
      pattern: '/cagnotte/:reference/demander-un-virement/:cashOutReference',
      page: 'cagnotte/[reference]/demander-un-virement/[cashOutReference]'
    })
    .add({
      name: "beneficiary",
      pattern: '/cagnotte/:reference/beneficiaire',
      page: 'cagnotte/[reference]/beneficiaire'
    })
    .add({
      name: "create-gift-card",
      pattern: '/cagnotte/:reference/depenser/carte-cadeau',
      page: "cagnotte/[reference]/depenser/carte-cadeau"
    })
    .add({
      name: "gift-card-confirm",
      pattern: '/cagnotte/:reference/depenser/carte-cadeau/:orderReference/confirmation',
      page: "cagnotte/[reference]/depenser/carte-cadeau/[orderReference]/confirmation"
    })
    .add({
      name: "offer-validation",
      pattern: '/cagnotte/:potReference/offrir-cagnotte/:reference',
      page: "cagnotte/[reference]/offrir-cagnotte/[offerReference]"
    })

    /*USER PAGES*/
    .add({
      name: "login",
      pattern: '/connexion',
      page: "user/login"
    })
    .add({
      name: "signup",
      pattern: '/inscription',
      page: "user/signup"
    })
    .add({
      name: "activate-account",
      pattern: '/activer-mon-compte/:reference',
      page: "user/activate-account"
    })
    .add({
      name: "confirm-email-update",
      pattern: '/changer-email/:reference',
      page: "user/confirm-email-update"
    })
    .add({
      name: "reset-password",
      pattern: '/mot-de-passe-oublie/:reference',
      page: "user/reset-password"
    })

    /*DASHBOARD PAGES*/
    .add({
      name: "dashboard",
      pattern: '/tableau-de-bord',
      page: "tableau-de-bord"
    })
    .add({
      name: "dashboard-pots",
      pattern: "/mes-cagnottes",
      page: "tableau-de-bord/mes-cagnottes"
    })
    .add({
      name: "dashboard-spending",
      pattern: "/mes-depenses",
      page: "tableau-de-bord/mes-depenses"
    })
    .add({
      name: "dashboard-kyc",
      pattern: "/mes-documents",
      page: "tableau-de-bord/mes-documents"
    })


    /*NEWSLETTER PAGES*/
    .add({
      name: "unsubscribe",
      pattern: '/desinscription-newsletter',
      page: "desinscription-newsletter"
    })
    .add({
      name: "subscribe",
      pattern: '/inscription-newsletter',
      page: "inscription-newsletter"
    })

    /*COOKIE PAGES*/
    .add({
      name: "manage-cookies",
      pattern: '/utilisation-des-cookies',
      page: "manage-cookies"
    })
    .add({
      name: "cookies",
      pattern: '/utilisations-des-cookies',
      page: "cookies"
    });





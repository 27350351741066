import React, {useState} from "react";
import AwesomeModal from "react-responsive-modal";
import css from './Modal.module.scss';

const Modal = (props) => {

  return (<AwesomeModal
    center
    classNames={{
      overlay: css.modal__overlay,
      modal: css.modal + " " + props.customClass,
      transitionEnter: css.modal__transition_enter,
      transitionEnterActive: css.modal__transition_enter_active,
      transitionExit: css.modal__transition_exit,
      transitionExitActive: css.modal__transition_exit_active,

    }}
    showCloseIcon={false}
    open={props.visible ? props.visible : false}
    onClose={() => props.onHide && props.onHide()}>
    <div className={css.modal__content_wrapper}>
      {!props.hideCloseButton && (<button onClick={() => props.onHide && props.onHide()} type="button"
                                          className={css.modal__close_button}>
        <img srcSet={'/static/images/pictos/icon-popup-close-cta.svg'}/>
      </button>)}
      {props.title && (<h3 className={props.titleCustomClass ? props.titleCustomClass : ''}
                           dangerouslySetInnerHTML={{__html: props.title}}/>)}
      {props.children}
    </div>
  </AwesomeModal>);
};

export default Modal;

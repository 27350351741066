/**
 * AnalyticsService
 * @module Services/utils/CookiesService
 * @description Offers utility functions to handle strings
 */
import Cookies from 'universal-cookie';

const self = {};

const LOCAL_STORAGE_MARKETING_TAG = 'marketing_active';
self.MARKETING__COOKIE_TOOGLED_EVENT_NAME = 'toogledMarketingCookie';


/**
 * TODO
 * @author Hassen Charef
 * @alias getInitials
 * @memberof module:Services/utils/CookiesService
 */
self.enableMarketingCookie = () => {
  localStorage.setItem(LOCAL_STORAGE_MARKETING_TAG, "enable");
  const event = new Event(self.MARKETING__COOKIE_TOOGLED_EVENT_NAME);
  globalThis.dispatchEvent(event);
};

self.isMarketingCookieActive = () => {
  return (localStorage.getItem(LOCAL_STORAGE_MARKETING_TAG) === "enable");
};

self.getMarketingCookies = () => {
  return ["__Secure-3PSIDCC", "__Secure-1PAPISID", "__Secure-1PSID", "SIDCC", "SID", "__Secure-3PAPISID", "SAPISID", "SAPISID", "HSID", "SSID", "SAPISID", "NID", "SAPISID", "OGP", "SAPISID", "ANID", "CONSENT", "SAPISID", "SAPISID"]
};

self.disableMarketingCookie = () => {
  localStorage.setItem(LOCAL_STORAGE_MARKETING_TAG, "disable");
  const event = new Event(self.MARKETING__COOKIE_TOOGLED_EVENT_NAME);
  globalThis.dispatchEvent(event);
  const cookies = new Cookies();
  let cookiesToDelete = self.getMarketingCookies();
  cookiesToDelete.forEach((cookie) => {
    cookies.remove(cookie, {domain: ".google.com"});
  })
};

export default self;

import React from "react";
import css from './ButtonSpinner.module.scss'

const ButtonSpinner = ({loading, customClass}) => {
  return (<div
      className={`${css.button_spinner_container} ${loading ? css.button_spinner_container__visible : ''} ${customClass ? customClass : ''}`}>
    <div className={`${css.button_spinner}`}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>)
};

export default ButtonSpinner;

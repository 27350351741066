/**
 * AnalyticsService
 * @module Services/utils/AnalyticsService
 * @description Offers utility functions to handle strings
 */
import _ from 'lodash';
import ReactGA from "react-ga";
import Cookies from 'universal-cookie';
import { CardTypes } from '../../models/PaymentCardTypes';
import { PotCategory } from '../../models/Pot';

const self = {};

const LOCAL_STORAGE_GA_TAG = 'analytics_active';

/**
 * TODO
 * @author Hassen Charef
 * @alias getInitials
 * @memberof module:Services/utils/AnalyticsService
 */
self.enableAnalyticsCookie = () => {
  localStorage.setItem(LOCAL_STORAGE_GA_TAG, "enable");
};

self.isAnalyticsActive = () => {
  const res = (localStorage.getItem(LOCAL_STORAGE_GA_TAG) === "enable");
  return res;
};

self.isGTMActive = () => {
  return self.isAnalyticsActive();
};

self.disableAnalyticsCookie = () => {
  localStorage.setItem(LOCAL_STORAGE_GA_TAG, "disable");
};

export default self;

export const PotCategory = {
  BIRTHDAY: 0,
  FAREWELL: 1,
  COMMON_GIFT: 2,
  HOUSE_WARMING: 3,
  BACHELOR_PARTY: 4,
  TRIP: 5,
  BIRTH: 6,
  WEDDING_LIST: 7,
  OTHER: 8
};


export const ParticipationType = {
  REGULAR: 0,
  SUGGESTED: 1,
  FIXED: 2
};


export const PotStatus = {
  RUNNING: 0,
  DEACTIVATED: 1,
  DELETED: 2,
  ARCHIVED: 3
};

export const PotCashOutOwner = {
  ORGANIZER: 0,
  BENEFICIARY: 1
};
